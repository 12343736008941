<template>
    <div class="company-contain">
        <div class="head-box">
            <div class="left-box">
                <div class="title">商品筛选</div>
                <div class="select-box" style="margin-left: 10px;">
                    <el-select v-model="value" placeholder="请选择" @change="getAllClass">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="addbutton" @click="publishTask">
                <span>+</span>
                <span>添加新商品</span>
            </div>
        </div>
        <div class="divide">
            <el-divider></el-divider>
        </div>
        <div class="table-box">
            <el-table
                    :data="productList"
                    class="college-table"
                    style="width: 100%; flex: 1" height="1%"
                    size="medium"
                    :header-cell-style="{fontWeight: 'normal', color: '#333333', background: '#f5f7fa',}"
                    :cell-style="{fontSize: '12px',color: '#333333'}"
            >
                <el-table-column
                        prop="goods_name"
                        label="商品信息"
                        width="200"
                        align="center"
                        class-name="student-name"
                >
                    <template slot-scope="scope">
                        <div class="showing">
                            <img class="imgBox" :src=scope.row.goods_cover_file>
                            <span class="showing-name">{{scope.row.goods_name}}</span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="goods_price" label="价格" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">￥{{scope.row.goods_price}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="putaway_time" label="上架时间" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.putaway_time}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="editCompany(scope.row)">修改商品</el-button>
                        <el-button type="text" v-if="scope.row.goods_state !=1" size="small"
                                   @click="delCompany(scope.row)">下架商品
                        </el-button>
                        <el-button type="text" v-if="scope.row.goods_state ===1" size="small"
                                   @click="putawayGoods(scope.row)">上架商品
                        </el-button>
                        <el-button type="text" size="small" @click="deleteGoods(scope.row)" style="color: #FF3333">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="pages-center"
                    style="padding: 10px;"
                    :current-page="adminPages.currentPageNum"
                    :page-size="adminPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="adminPages.total"
                    @current-change="adminCurrentChange"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'taskPublish',
        data() {
            return {
                options: [{
                    value: 2,
                    label: '全部'
                }, {
                    value: 0,
                    label: '已上架'
                }, {
                    value: 1,
                    label: '未上架'
                }],
                value: 2,
                productList: [],//任务列表
                //分页
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                //编辑获得的数据
                editForm: {
                    id: '',
                    tasktype: "",//任务分类
                    title: "", //标题
                    describe: "", //描述
                    cover: "", //封面
                    time: "", //截至时间
                    Islimit: "",//是否限制人数 0否 1是
                    limitnum: "",//限制人数
                    taskmoney: "",//任务金额
                    apply: "",//是否报名 0否 1是
                    file: [] //附件
                }
            }
        },
        mounted() {
            this.getAllClass(2);
        },
        methods: {
            // 获取商品列表
            getAllClass(id) {
                let param = {
                    page: this.adminPages.currentPageNum,
                    limit: this.adminPages.eachPageNum, //页面显示条数
                    type: this.value
                };
                //全部
                if (id == 2) {
                    delete param.type;
                }
                this.$httpcompany.axiosGetBy(this.$api.goodsList, param, res => {
                    if (res.code == 200) {
                        this.productList = res.list;
                        this.adminPages.total = res.total;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });

            },
            //分页
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getAllClass();
            },
            // 编辑
            editCompany(row) {
                this.editForm.id = row.goods_id;
                this.editForm.name = row.goods_name;
                this.editForm.cover = row.goods_cover_file;
                this.editForm.price = row.goods_price;
                this.editForm.commission = row.goods_commission;
                this.editForm.coupon_url = row.coupon_url;
                this.editForm.url = row.url;
                this.$router.push({
                    path: "/company/product/add",
                    query: {editForm: JSON.stringify(this.editForm)}
                })
            },
            // 删除
            delCompany(row) {
                let that = this;
                const id = row.goods_id;
                let param = {
                    goods_id: id
                };
                this.$confirm("确定下架商品吗？", "下架商品", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    that.$httpcompany.axiosGetBy(that.$api.goodsChangeState, param, res => {
                        if (res.code == 200) {
                            that.$message({
                                type: "success",
                                message: "下架成功"
                            });
                            that.getAllClass(this.value);
                        } else {
                            that.$message.warning(res.msg);
                        }
                    });
                });
            },
            putawayGoods(row) {
                let that = this;
                const id = row.goods_id;
                let param = {
                    goods_id: id
                };
                this.$confirm("确定上架商品吗？", "上架商品", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    that.$httpcompany.axiosGetBy(that.$api.goodsChangeState, param, res => {
                        if (res.code == 200) {
                            that.$message({
                                type: "success",
                                message: "上架成功"
                            });
                            that.getAllClass(this.value);
                        } else {
                            that.$message.warning(res.msg);
                        }
                    });
                });
            },
            deleteGoods(row) {
                let that = this;
                const id = row.goods_id;
                let param = {
                    goods_id: id
                };
                this.$confirm("确定删除商品吗？", "删除商品", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    that.$httpcompany.axiosGetBy(that.$api.goodsDel, param, res => {
                        if (res.code == 200) {
                            that.$message({
                                type: "success",
                                message: "删除成功"
                            });
                            that.getAllClass(this.value);
                        } else {
                            that.$message.warning(res.msg);
                        }
                    });
                });
            },
            //添加商品
            publishTask() {
                this.$router.push({path: "/company/product/add"})
            }
        },

    }
</script>

<style scoped lang="scss">
    .activeClass {
        background: #4d5aff;
        color: #ffffff;
    }

    .company-contain {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 100px);

        .head-box {
            display: flex;
            justify-content: space-between;
            padding: 10px 30px 0 20px;

            .left-box {
                display: flex;

                .title {
                    margin-top: 10px;
                }
            }

            .addbutton {
                display: flex;
                align-items: center;
                border: 1px solid #2338E6;
                cursor: pointer;
                width: 120px;
                height: 40px;

                span {
                    margin-left: 10px;
                    color: #2338E6
                }
            }
        }

        .divide {
            padding: 0 20px;
        }

        .filter-box {
            .box1 {
                padding: 0 20px 10px 20px;
                height: 40px;
                display: flex;

                span {
                    margin-top: 8px;
                    line-height: 32px;
                    display: inline-block;
                    width: 80px;
                    height: 32px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }

        .table-box {
            display: flex;
            flex-direction: column;
            height: 1%;
            flex: 1;

            .showing {
                display: flex;
                align-items: center;
                /*width: 30px;*/
                height: 40px;
                margin-left: 10px;

                img {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                }

                .showing-name {
                    max-width: 260px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
</style>
